export class CryptoSeguimientoModel {
  $key: string;
  rank: string;
  name: string;
  ticker: string;
  type: string;
  price: string;
  percentage24: string;
  marketCap: string;
  marketCappercentage: string;
  volume24: string;
  volume24percentage: string;
  circulatingSupply: string;
  maxSupply: string;
  totalSupply: string;
  icon: string;
  categoria: string;
}
