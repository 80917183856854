// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDr4wpi1z7Mvfe1w88s-U2_xM9BAFsahWw',
    authDomain: 'mantenedorclie.firebaseapp.com',
    databaseURL: 'https://mantenedorclie.firebaseio.com',
    projectId: 'mantenedorclie',
    storageBucket: 'mantenedorclie.appspot.com',
    messagingSenderId: '257684163039',
    appId: '1:257684163039:web:0ff41a4e97a09936a39c6a'
  }
};

// const admin = require('firebase-admin');
// admin.initializeApp();

// const db = admin.firestore();


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
