export class CargarExcelModel {
  $key: string;
  fecha: Date;
  market: string;
  type: string;
  price: string;
  amount: string;
  total: string;
  fee: string;
  feeCoin: string;
  icon: string;

  // Porpiedades de estilos
  sellClass: string;

  // Balance compra venta
  numCompras: string;
  numVentas: string;
}
