import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoinMarketCapService {

  constructor(
    private http: HttpClient
  ) { }

  testCoinMarketCap(): Observable<any> {
    const timestamp = Math.floor(Date.now()/1000); //unix timestamp in seconds;
    const signature ='7fe8d0717bfa5bbff443dae65da86f52b4abd06101785ff7fcd8e59a87482a40';

    // let paramsS = new HttpParams().set('timestamp', ''+timestamp);    // now it has aaa
    // paramsS = paramsS.set('signature', signature);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept':'application/json',
        'X-CMC_PRO_API_KEY': 'fcc3a314-6482-44b9-b362-9cab06281b1c'
        // 'Access-Control-Allow-Headers':'Content-Type',
        // 'Access-Control-Allow-Origin':'*'
      }),
      // params: paramsS
    };
    return this.http.get<any>('https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest', httpOptions);
  }

  latestSymbols(symbol: string): Observable<any> {

    // let paramsS = new HttpParams().set('symbol', 'BTC,ETH');
    let paramsS = new HttpParams().set('symbol', symbol);
    paramsS = paramsS.set('convert', 'USD');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept':'application/json',
        'X-CMC_PRO_API_KEY': 'fcc3a314-6482-44b9-b362-9cab06281b1c'
        // 'Access-Control-Allow-Headers':'Content-Type',
        // 'Access-Control-Allow-Origin':'*'
      }),
      params: paramsS
    };
    return this.http.get<any>('https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest', httpOptions);
  }

  metadatos(symbol: string): Observable<any> {

    // let paramsS = new HttpParams().set('symbol', 'BTC,ETH');
    let paramsS = new HttpParams().set('symbol', symbol);
    // paramsS = paramsS.set('convert', 'USD');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept':'application/json',
        'X-CMC_PRO_API_KEY': 'fcc3a314-6482-44b9-b362-9cab06281b1c'
        // 'Access-Control-Allow-Headers':'Content-Type',
        // 'Access-Control-Allow-Origin':'*'
      }),
      params: paramsS
    };
    return this.http.get<any>('https://pro-api.coinmarketcap.com/v1/cryptocurrency/info', httpOptions);
  }
}
