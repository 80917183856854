import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'customerXFilter'
})
export class SearchXPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter((val) => {
      // const rVal = (val.toLocaleLowerCase().includes(args.toLocaleLowerCase()));
      // return rVal;
      const rVal = (val.name.toLocaleLowerCase().includes(args.toLocaleLowerCase()));
      return rVal;
      // return val.filter(val => val.name.indexOf(args) !== -1);
    });

  }
}
