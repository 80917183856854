import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { CargarExcelModel } from 'src/app/models/cargarExcelModel';

@Injectable({
  providedIn: 'root'
})
export class CargarExcelService {

  public cargarExcelModelList: AngularFireList<any>;

  public cargarList: AngularFireList<any>;
  selectedCargarExcel: CargarExcelModel = new CargarExcelModel();

  constructor(
    private firebase: AngularFireDatabase
  ) { }


  /**
   * Obtiene las crypto de seguimiento CoinMarketCap o Binance
   */
  getCargarExcelModel() {
    return this.cargarExcelModelList = this.firebase.list('CargarExcel');
  }


  insertCargarExcelModel(excelModel: CargarExcelModel) {
    this.cargarExcelModelList.push({
      fecha: excelModel.fecha,
      market: excelModel.market,
      type: excelModel.type,
      price: excelModel.price,
      amount: excelModel.amount,
      total: excelModel.total,
      fee: excelModel.fee,
      feeCoin: excelModel.feeCoin,
    });
  }


}
