import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { CryptoSeguimientoModel } from 'src/app/models/cryptosSegui';
import { CryptoBalanceModel } from 'src/app/models/cryptosBalanceModel';
import { CryptosMiasModel } from 'src/app/models/cryptosMiasModel';

@Injectable({
  providedIn: 'root'
})
export class CryptosService {

  public cyptoSeguimientoList: AngularFireList<any>;
  selectedCyptoSeguimiento: CryptoSeguimientoModel = new CryptoSeguimientoModel();

  public cyptoBalanceList: AngularFireList<any>;
  selectedCyptoBalance: CryptoBalanceModel = new CryptoBalanceModel();


  public cryptosMiasList: AngularFireList<any>;
  selectedCryptosMias: CryptosMiasModel = new CryptosMiasModel();



  constructor(
    private firebase: AngularFireDatabase
  ) { }

  /**
   * Obtiene las crypto de seguimiento CoinMarketCap o Binance
   */
  getCryptosSeguimiento() {
    const list : AngularFireList<any> = this.firebase.list('CryptosSeguimiento');
    console.log('getCryptosSeguimiento - list', list);
    return this.cyptoSeguimientoList = list;
  }

  /**
   * Obtiene las crypto de seguimiento CoinMarketCap o Binance
   */
  getCryptosSeguimientoPC() {
    return this.cyptoSeguimientoList = this.firebase.list('CryptosSeguimientoPC');
  }

   /**
   * Obtiene las crypto de seguimiento CoinMarketCap o Binance
   */
  getCryptosSeguimientoPorTipo(tipo: string) {
    return this.cyptoSeguimientoList = this.firebase.list('CryptosSeguimiento');
  }

  /**
   * Obtiene Todas mis Cryptos adquiridas y vendidas
   */
  // getCryptosMias() {
  //   return this.cyptoBalanceList = this.firebase.list('CryptosBalance');
  // }
  // insertCryptoBalance(cryptoB: CryptoBalanceModel) {
  //   this.cyptoBalanceList.push({
  //     par: cryptoB.par,
  //     fecha: cryptoB.fecha,
  //     lado: cryptoB.lado,
  //     precioUsdt: cryptoB.precioUsdt,
  //     completo: cryptoB.completo,
  //     comisionValor: cryptoB.comisionValor,
  //     comisionTiker: cryptoB.comisionTiker,
  //     totalUsdt: cryptoB.totalUsdt,
  //     favorita: cryptoB.favorita
  //   });
  // }

  // updateCryptoBalance(cryptoB: CryptoBalanceModel) {
  //   this.cyptoBalanceList.update(cryptoB.$key, {
  //     par: cryptoB.par,
  //     fecha: cryptoB.fecha,
  //     lado: cryptoB.lado,
  //     precioUsdt: cryptoB.precioUsdt,
  //     completo: cryptoB.completo,
  //     comisionValor: cryptoB.comisionValor,
  //     comisionTiker: cryptoB.comisionTiker,
  //     totalUsdt: cryptoB.totalUsdt,
  //     favorita: cryptoB.favorita
  //   });
  // }

  // deleteCryptoBalance($key: string) {
  //   this.cyptoBalanceList.remove($key);
  // }

  getCryptosMias() {
    return this.cryptosMiasList = this.firebase.list('CryptosMias');
  }

  insertCryptosMias(cryptoB: CryptosMiasModel) {
    this.cryptosMiasList.push({
      par: cryptoB.par,
      fecha: cryptoB.fecha,
      lado: cryptoB.lado,
      precioUsdt: cryptoB.precioUsdt,
      completo: cryptoB.completo,
      comisionValor: cryptoB.comisionValor,
      comisionTiker: cryptoB.comisionTiker,
      totalUsdt: cryptoB.totalUsdt,
      favorita: cryptoB.favorita,
      tipo: cryptoB.tipo
    });
  }

  updateCryptosMias(cryptoB: CryptosMiasModel) {
    this.cryptosMiasList.update(cryptoB.$key, {
      par: cryptoB.par,
      fecha: cryptoB.fecha,
      lado: cryptoB.lado,
      precioUsdt: cryptoB.precioUsdt,
      completo: cryptoB.completo,
      comisionValor: cryptoB.comisionValor,
      comisionTiker: cryptoB.comisionTiker,
      totalUsdt: cryptoB.totalUsdt,
      favorita: cryptoB.favorita,
      tipo: cryptoB.tipo
    });
  }

  deleteCryptosMias($key: string) {
    this.cryptosMiasList.remove($key);
  }

}
