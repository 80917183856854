import { CargarExcelModel } from "./cargarExcelModel";

export class CargarExcelBalanceModel {
  $key: string;
  fecha: Date;
  market: string;
  type: string;
  priceProm: string;
  amountTotal: string;
  totalTotal: string;
  feeTotal: string;
  feeCoinTotal: string;
  icon: string;

  listaBuy: Array <CargarExcelModel>;
  listaSell: Array <CargarExcelModel>;


  // Porpiedades de estilos
  sellClass: string;

  // Balance compra venta
  numBuy: number;
  numSell: number;
  inverRecuperada: string;
  gananciaEfectiva: string = '0';

}
