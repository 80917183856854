import { Component, OnInit } from '@angular/core';
import { CargarExcelService } from 'src/app/servicios/excel/cargar-excel.service';
import { AngularFireStorage } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { CargarExcelModel } from 'src/app/models/cargarExcelModel';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { isNullOrUndefined } from 'util';
import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-cargar-excel-ope',
  templateUrl: './cargar-excel-ope.component.html',
  styleUrls: ['./cargar-excel-ope.component.css']
})
export class CargarExcelOpeComponent implements OnInit {

  uploadPorcent: Observable<number>;

  urlFile: Observable<string>;
  urlFileString: string;
  isFileDisabled: Boolean = false;

  listaOpNuevas: CargarExcelModel[] = [];
  listaOpExistentes: CargarExcelModel[] = [];
  listaOpExcel: CargarExcelModel[] = [];
  listaOpBD: CargarExcelModel[] = [];

  msj: string = '';

  constructor(
    private cargarExcelService: CargarExcelService,
    public router: Router,
    private toastrService: ToastrService,
    public flasMensaje: FlashMessagesService,

    public angularFireStorage: AngularFireStorage
  ) { }

  ngOnInit() {
    //para poder insertar primerro debemos consultar a la tabla para generar la referencia
    this.cargarExcelService.getCargarExcelModel();

    this.getOpesBD();

    this.isFileDisabled = null

  }

  getOpesBD(){
    this.cargarExcelService.getCargarExcelModel()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.listaOpBD = [];
      let i = 1;
      item.forEach(element => {
        // this.totalItems ++;
        // console.log('element.key: ',element.key);
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['id'] = i++;
        x['$key'] = element.key;
        // Agregar Class
        // x['sellClass'] = StringUtils.addClassDanger(x['type'], 'SELL');

        // Limpiar Caracteres
        // x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
        this.listaOpBD.push(x as CargarExcelModel);
        // console.log('id: ', x['id']);
      });
    });

    // this.listaOpBD.sort(function (a, b) {
    //   if (a.fecha > b.fecha) {
    //     return 1;
    //   }
    //   if (a.fecha < b.fecha) {
    //     return -1;
    //   }
    //   // a must be equal to b
    //   // return 0;
    // });
  }


  onFileChange(ev: any, excelForm: NgForm) {
    // Cargar excel en firebase
    try {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        // console.log('jsonData: ',jsonData);

        jsonData.sheet1.forEach(hoja => {
          let r =  new CargarExcelModel();
          r.fecha = hoja['Date(UTC)'];
          r.market = hoja['Market'];
          r.type = hoja['Type'];
          r.price = hoja['Price'];
          r.amount = hoja['Amount'];
          r.total = hoja['Total'];
          r.fee = hoja['Fee'];
          r.feeCoin = hoja['Fee Coin'];

          // this.cargarExcelModelList.push(x as CargarExcelModel);
          // this.cargarExcelModelList.push(r);
          // this.cargarExcelService.insertCargarExcelModel(r);
          this.listaOpExcel.push(r);

        });

        // Comprueba si los datos del exel existen en BD y los separa por existentes y nuevos
        this.listaOpExcel.forEach(opE => {
          const opBD =  this.listaOpBD.find(x => x.fecha == opE.fecha && x.market == opE.market);
          if(isNullOrUndefined(opBD)){
            this.listaOpNuevas.push(opE);
            // this.cargarExcelService.insertCargarExcelModel(r);
          } else{
            this.listaOpExistentes.push(opE);
          }
        });

        this.registraOperaciones(ev, excelForm);
      }

      reader.readAsBinaryString(file);

    } catch (error) {
      this.toastrService.success('This is success toast.', 'Success!');
      this.flasMensaje.show('Operacion exitosa', {cssClass: 'alert-success', timeout: 4000});
    }
  }

  setMensajeProcesarExel(){
    this.msj = 'El fichero procesado tiene: '+
    '\n   - [' + this.listaOpExistentes.length +'] operaciones existentes en nuestro sistema.'+
    '\n   - [' + this.listaOpNuevas.length +'] operaciones nuevas. '+
    '\n Solo se registraran las que se detectaron como nuevas...'
  }

  registraOperaciones(ev: any, excelForm: NgForm) {
    this.setMensajeProcesarExel();
    if (0 < this.listaOpExistentes.length
      && 0 === this.listaOpNuevas.length) {
        alert('No se registrará la información ya que los datos del fichero existen en nuestro sistema.' +
        '\n   - [' + this.listaOpExistentes.length +'] operaciones existentes en nuestro sistema.');
    } else {
      if (confirm(this.msj + '\n\n     ¿Desea subir la información?')) {
        try {
          this.listaOpNuevas.forEach(opN =>{
            this.cargarExcelService.insertCargarExcelModel(opN);
          });
          // this.onUpload(ev);

          this.toastrService.success('Operacion exitosa', 'Carga de historial de operciones.');
          this.flasMensaje.show('Archivo de Operaciones subido Exitosamente', {cssClass: 'alert-success', timeout: 4000});

          this.cancelar(excelForm);
        } catch (error) {
          this.toastrService.error('This is Error toast.', 'Error!');
          this.flasMensaje.show('Error en la Operacion', {cssClass: 'alert-error', timeout: 4000});
        }
      }
    }
  }

   /**
   * Subir imagen a Fiebase
   * @param e
   */
  onUpload(e: any) {
    console.log('Subir', e.target.files[0]);
    console.log('Tamaño', e.target.files[0].size);
    // generar numero aleatore
    const id = Math.random().toString(36).substring(2);
    const file = e.target.files[0];

    const filePath = 'cargasExcel/'+ id + '_' + e.target.files[0].name;

    const ref = this.angularFireStorage.ref(filePath);
    const task = this.angularFireStorage.upload(filePath, file);

    this.uploadPorcent = task.percentageChanges();
    task.snapshotChanges().pipe(finalize(() => this.urlFile = ref.getDownloadURL())).subscribe();

  }

   /**
   * Limpiar formulario y redireccionar a lista de pagos
   * @param pagoForm
   */
  cancelar(form?: NgForm) {
    this.cleanForm(form);
    this.router.navigate(['/listaOperaciones']);
  }

   /**
   * Resetear selectedPago
   * @param pagoForm
   */
  cleanForm(form?: NgForm) {
    if (form != null) {
      form.reset();
      this.cargarExcelService.selectedCargarExcel =  new CargarExcelModel();
    }

    this.isFileDisabled = true;
    this.uploadPorcent = null;
    this.urlFile = null;
    this.urlFileString = null;
  }

}
