import { Component, OnInit } from '@angular/core';
import { CryptoBalanceModel } from 'src/app/models/cryptosBalanceModel';
import { CryptosService } from 'src/app/servicios/crypto/cryptos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { CryptoSeguimientoModel } from 'src/app/models/cryptosSegui';
import { CryptosMiasModel } from 'src/app/models/cryptosMiasModel';
import StringUtils from 'src/app/utils/string-utils';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {

  cryptoBalanceList: CryptoBalanceModel[] = [];

  // ngx-pagination
  itemsPerPage = 10;
  pageActual = 1;

 cryptosMiasModelList: CryptosMiasModel[] = [];

 cryptoSeguimientoList : CryptoSeguimientoModel[];

  constructor(
    private cryptosService: CryptosService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private flasMensaje: FlashMessagesService
  ) { }

  ngOnInit() {
    this.getCryptoBalance();
  }

  /**
   * Determina si debe cargar los pagos de XCliente o de todos
   */
  getCryptoBalance() {
    // Obtener seguimiento
    this.getCryptoSeguimiento();

    // Obtener la Mias
    this.getCryptosMias();
  }

  /**
   * Determina si debe cargar los pagos de XCliente o de todos
   */
  getCryptoSeguimiento() {
    // Obtener seguimiento
    this.cryptosService.getCryptosSeguimiento()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.cryptoSeguimientoList = [];
      let i = 1;

      item.forEach(element => {
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['id'] = i++;
        x['$key'] = element.key;

        // Limpiar Caracteres
        x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
        x['price'] = StringUtils.limpiarDatoRemplazaX(x['price'], '$');

        // Agregar Objeto Limpio
        this.cryptoSeguimientoList.push(x as CryptoSeguimientoModel);
        // console.log('id: ', x['id']);
      });
    });
  }

  /**
   * Determina si debe cargar los pagos de XCliente o de todos
   */
  getCryptosMias() {
    // Obtener la Mias
    this.cryptosService.getCryptosMias()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.cryptosMiasModelList = [];
      let i = 1;
      item.forEach(element => {
        try {
          const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
          let cryptoBalanceModel =  new CryptoBalanceModel();
          const tikerMia = StringUtils.getTikerMias(x['par']);
          const cryptoS: CryptoSeguimientoModel = this.cryptoSeguimientoList.find(cs => cs.ticker === tikerMia);

          if (!isNullOrUndefined(cryptoS)) {
            if('NPXS' === tikerMia){
              console.log('cryptoS: ', cryptoS);
            }
            cryptoBalanceModel.id = '' + i++;
            cryptoBalanceModel.fecha = x['fecha'];
            cryptoBalanceModel.tipo = x['tipo'];
            cryptoBalanceModel.par = x['par'];
            cryptoBalanceModel.precioUsdt = x['precioUsdt'];
            cryptoBalanceModel.completo = x['completo'];
            cryptoBalanceModel.totalUsdt = x['totalUsdt'];
            cryptoBalanceModel.precioActualUsdt = cryptoS.price;
            cryptoBalanceModel.valorActual = StringUtils.calcularValorActual(x['completo'], cryptoBalanceModel.precioActualUsdt).toFixed(8).toString();
            cryptoBalanceModel.ganancia = StringUtils.calcularGanancia(x['completo'], cryptoS.price, x['totalUsdt']).toFixed(8).toString();
            cryptoBalanceModel.gananciaPorcentaje = ''+ StringUtils.calcularGananciaPorcentaje(x['totalUsdt'], cryptoBalanceModel.valorActual).toFixed(2);

            cryptoBalanceModel.gananciaClass = StringUtils.validarDatoNegativoAddClass(cryptoBalanceModel.ganancia, null);
            cryptoBalanceModel.gananciaPorcentajeClass = StringUtils.validarDatoNegativoAddClass(cryptoBalanceModel.gananciaPorcentaje, null);

            this.cryptoBalanceList.push(cryptoBalanceModel);
          }
        } catch (error) {
            console.log('error', error);
        }
      });
    });
    console.log('this.cryptoBalanceList: ',this.cryptoBalanceList);

  }

  irBinance(par: string){
    const tickerMia = StringUtils.getTikerMias(par);
    // abrir un PDF en una pestaña nueva
    // window.open('http://ejemplo.com/archivo.pdf', '_blank');

    // redirigir la pestaña actual a otra URL
    // window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');

    window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');
  }


}
