import { isNullOrUndefined } from "util";

export default class StringUtils {

   /**
   * Valida la fecha de renovacion y asigna un classs para pintar en frontend
   * @param fecha
   */
  static validarFechaAddClass(fecha: any, hoy: Date) {
    let resp = 'table-success';

    const fechaS = fecha.split('-');

    const f = new Date();
    f.setDate(fechaS[0]); // Dia
    f.setMonth(fechaS[1] - 1); // Mes
    f.setFullYear(fechaS[2]);

    // console.log('this.hoy: ', hoy);
    // console.log('f: ', f);

    if (hoy > f) {
      resp = 'table-danger';
    }
    return resp;
  }

  /**
   * Valida si el dato es negativo o positivo y retorna la clasea mostrar
   * @param dato
   */
  static addClassDanger(dato: any, dato2: string ){
    let resp = '';
    if (!isNullOrUndefined(dato) && !isNullOrUndefined(dato2)
    && dato == dato2) {
      resp = 'table-danger';
    }
    return resp;
  }

  /**
   * Valida si el dato es negativo o positivo y retorna la clasea mostrar
   * @param dato
   */
  static validarDatoNegativoAddClass(dato: any, limpiar: string ){
    let resp = '';
    let dato2 = null;
    // Limpiar dato entrdad
    if (!isNullOrUndefined(limpiar)) {
      dato2 = this.limpiarDato(dato, limpiar);
    // console.log('dato2: ', dato2);
    } else {
      dato2 = dato;
    }

    // Si la gancia es mayor a cero pone en verde
    if (!isNullOrUndefined(dato2) && Number(dato2) > 0) {
      resp = 'table-success';
    } else {
      resp = 'table-danger';
    }
    return resp;
  }

  /**
   * Valida si el dato es negativo o positivo y retorna la clasea mostrar
   * @param dato
   */
  static validarGananciaXAddClass(dato: any, limpiar: string,  ganaciaX: number){
    let resp = '';
    let dato2 = null;
    // Limpiar dato entrdad
    if (!isNullOrUndefined(limpiar)) {
      dato2 = this.limpiarDato(dato, limpiar);
    // console.log('dato2: ', dato2);
    } else {
      dato2 = dato;
    }

    // Si la Ganancia es mayor a ganaciaX ya deveria haberse recuperado la inverion inicial y conviene vender
    if (!isNullOrUndefined(dato2)) {
      if (Number(dato2) > ganaciaX) {
        resp = 'table-success';
      } else if( 0  > ganaciaX) {
        resp = 'table-danger';
      }
    }
    return resp;
  }

  static limpiarDato(dato: string, datoLimpiar: string) {
    let resp = '';
    if (-1 < dato.indexOf(datoLimpiar)) {
      resp = dato.replace(datoLimpiar, '');
    }
    return resp;
  }

  static limpiarDato2(dato: string, datoLimpiar: string) {
    let resp = dato;
    if (-1 < dato.indexOf(datoLimpiar)) {
      resp = dato.replace(datoLimpiar, '');
    }
    return resp;
  }

  static limpiarDatoRemplazaX(dato: string, datoLimpiar: string) {
    let resp = '';
    if (-1 < dato.indexOf(datoLimpiar)) {
      // resp = dato.replace(datoLimpiar, '').replace('.', ',');
      resp = dato.replace(datoLimpiar, '');
    } else{
      resp = dato;
    }
    return resp;
  }

  static limpiarDatoRemplazaXporY(dato: string, x: string, y: string) {
    let resp = '';
    if (-1 < dato.indexOf(x)) {
      // resp = dato.replace(datoLimpiar, '').replace('.', ',');
      resp = dato.replace(x, y);
    } else{
      resp = dato;
    }
    return resp;
  }

  static getTikerMias(dato: string) {
    let resp = '';
    if (!isNullOrUndefined(dato)) {

      if (dato.indexOf('/') > -1) {
        resp = dato.replace(' ', '').split('/')[0];

      } else if (dato.indexOf('USDT') > -1) {
        resp = dato.replace(' ', '').split('USDT')[0];

      } else if (dato.indexOf('BUSD') > -1) {
        resp = dato.replace(' ', '').split('BUSD')[0];
      }
    }
    return resp;
  }

  static getTikerMias2(dato: string, datoSplit: string) {
    let resp = '';
    if (!isNullOrUndefined(dato)) {
      if ('IOTAUSDT' == dato) {
        // console.log('getTikerMias2: ', dato);
        // console.log('getTikerMias2: ', datoSplit);
        dato = 'MIOTAUSDT';
      }

      if (dato.indexOf('USDT') > -1) {
        resp = dato.replace(' ', '').split(datoSplit)[0];
      } else if (dato.indexOf('BUSD') > -1) {
        resp = dato.replace(' ', '').split('BUSD')[0];
      } else if (dato.indexOf('BTC') > -1) {
        resp = dato.replace(' ', '').split('BTC')[0];
      } else {
        resp = dato;
      }
    }
    return resp;
  }

  static calcularValorActual(cantidad: string, precioActual: string) {
    let resp: number;

    if (!isNullOrUndefined(cantidad) && !isNullOrUndefined(precioActual)) {
      let pa = this.limpiarDatoRemplazaX(precioActual, ',');

      //quita el punto de miles
      let c = this.limpiarDatoRemplazaX(cantidad, '.');

      // remplaza la coma de decimal por punto
      c = this.limpiarDatoRemplazaXporY(c, ',', '.');

      resp = parseFloat(c) * parseFloat(pa);
      // console.log('calcularValorActual', parseFloat(cantidad) * parseFloat(precioActual));
      // console.log('resp', resp);
    }
    return resp;
  }

  static calcularValorActual2(cantidad: string, precioActual: string) {
    let resp: number;
    if (!isNullOrUndefined(cantidad) && !isNullOrUndefined(precioActual)) {
      resp = parseFloat(cantidad) * parseFloat(this.limpiarDatoRemplazaX(precioActual, ','));
    }
    return resp;
  }

  static calcularValorActual3(cantidad: string, precioActual: string) {
    let resp: number;
    if (!isNullOrUndefined(cantidad) && !isNullOrUndefined(precioActual)) {
      resp = parseFloat(cantidad) * parseFloat(this.limpiarDatoRemplazaX(precioActual.toString(), ','));
    }
    return resp;
  }

  static calcularGanancia(cantidad: string, precioActual: string, totalUsdt: string) {
    let resp: number;

    if (!isNullOrUndefined(cantidad)
    && !isNullOrUndefined(precioActual)
    && !isNullOrUndefined(totalUsdt)) {
      //quita el punto de miles
      let c = this.limpiarDatoRemplazaX(cantidad, '.');

      // remplaza la coma de decimal por punto
      c = this.limpiarDatoRemplazaXporY(c, ',', '.');

      let pa = this.limpiarDatoRemplazaX(precioActual, ',');
      let tu = this.limpiarDatoRemplazaXporY(totalUsdt, ',', '.');

      resp = (parseFloat(c) * parseFloat(pa)) - parseFloat(tu);
      // console.log('calcularGanancia', (parseFloat(cantidad) * parseFloat(precioActual)) - parseFloat(totalUsdt));
      // console.log('resp', resp);
    }
    return resp;
  }

  static calcularGanancia2(cantidad: string, precioActual: string, totalUsdt: string) {
    let resp: number;

    if (!isNullOrUndefined(cantidad)
    && !isNullOrUndefined(precioActual)
    && !isNullOrUndefined(totalUsdt)) {

      resp = (parseFloat(precioActual) * parseFloat(cantidad)) - parseFloat(totalUsdt);
      // console.log('precioActual: ', precioActual);
      // console.log('cantidad: ', cantidad);
      // console.log('totalUsdt: ', totalUsdt);
      // console.log('resp: ', resp);
    }
    return resp;
  }
  static calcularGanancia3(cantidad: string, precioActual: string, totalUsdt: string) {
    let resp: number;

    if (!isNullOrUndefined(cantidad)
    && !isNullOrUndefined(precioActual)
    && !isNullOrUndefined(totalUsdt)) {

      resp = (parseFloat(this.limpiarDatoRemplazaX(precioActual.toString(), ',')) * parseFloat(cantidad)) - parseFloat(totalUsdt);
      // console.log('precioActual: ', precioActual);
      // console.log('cantidad: ', cantidad);
      // console.log('totalUsdt: ', totalUsdt);
      // console.log('resp: ', resp);
    }
    return resp;
  }

  static calcularGananciaPorcentaje(totalUsdt: string, valorActual: string) {
    let resp: number;

    if (!isNullOrUndefined(totalUsdt)
    && !isNullOrUndefined(valorActual)) {
      let va = this.limpiarDatoRemplazaX(valorActual, ',');
      let tu = this.limpiarDatoRemplazaXporY(totalUsdt, ',', '.');
      // resp = 1 - (parseFloat(tu) / parseFloat(va));
      resp = ((parseFloat(va) - parseFloat(tu)) / parseFloat(va)) * 100;

    }
    return resp;
  }

  static calcularGananciaPorcentaje2(totalUsdt: string, valorActual: string) {
    let resp: number;

    if (!isNullOrUndefined(totalUsdt)
    && !isNullOrUndefined(valorActual)) {
      resp = ((parseFloat(valorActual) - parseFloat(totalUsdt)) / parseFloat(valorActual)) * 100;
      // resp = ((parseFloat(valorActual) - parseFloat(totalUsdt)) / parseFloat(valorActual));

    }
    return resp;
  }

}
