import { CargarExcelModel } from "../models/cargarExcelModel";
import { isNullOrUndefined } from "util";

export default class BalanceUtils {

  static sumaPreciosBuySell(listBuy: CargarExcelModel[], flujo: string): number{
    let sumaTotalBuy: number = 0;

    if (!isNullOrUndefined(listBuy)) {

      listBuy.forEach(lb => {
        if ('priceProm' == flujo) {
          sumaTotalBuy = sumaTotalBuy + Number(lb.price);
        } else if ('amountTotal' == flujo) {
          sumaTotalBuy = sumaTotalBuy + Number(lb.amount);

        } else if ('totalTotal' == flujo) {
          sumaTotalBuy = sumaTotalBuy + Number(lb.total);

        } else if ('feeTotal' == flujo) {
          sumaTotalBuy = sumaTotalBuy + Number(lb.fee);
        }

      });
    }
    return sumaTotalBuy;
  }
}
