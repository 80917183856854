// import moment = require('moment');
// const moment2= require('moment') ;

import * as moment from 'moment';

export default class TablaUtils {

  // public static compararCamposFechas = (fecha1, fecha2) => {
  //   if (moment(fecha1).isBefore(fecha2)) {
  //       return 1;
  //   } else if (moment(fecha1).isAfter(fecha2)) {
  //       return -1;
  //   }
  //   // a must be equal to b
  //   return 0;
  // }

  public static compararCamposFechas2 = (fecha1: Date, fecha2: Date) => {
    const d1 = new Date(fecha1);
    const d2 = new Date(fecha2);

    if (moment(d1).isBefore(d2)) {
        return 1;
    } else if (moment(d1).isAfter(d2)) {
        return -1;
    }
    // a must be equal to b
    return 0;
  }

  public static compararCamposFechas3 = (fecha1: Date, fecha2: Date) => {
    const d1 = new Date(fecha1);
    const d2 = new Date(fecha2);

    if (moment(d2).isBefore(d1)) {
        return 1;
    } else if (moment(d2).isAfter(d1)) {
        return -1;
    }
    // a must be equal to b
    return 0;
  }
}
