export class CryptosMiasModel {
  $key: string;
  par: string;
  fecha: Date;
  lado: string;
  precioUsdt: string;
  completo: string;
  comisionValor: string;
  comisionTiker: string;
  totalUsdt: string;
  favorita: string;
  tipo: string;
}
