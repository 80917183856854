import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import * as sha256 from 'sha256';
// import crypto = require('crypto');
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class BinanceService {

  constructor(
    private http: HttpClient
  ) { }

  time(): Observable<string> {
     const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type':  'application/json',
         'Accept':'application/json',
         'X-MBX-APIKEY': 'RrNZGZ8q5ygHuxU3NDSuuRGIQBaeLAsvoeQuJUc8haQWfzUjLT6u50UvryA3DBs9'
         // 'Access-Control-Allow-Headers':'Content-Type',
         // 'Access-Control-Allow-Origin':'*'
       }),
      //  params: paramsS
     };
     return this.http.get<string>('/api/v3/time', httpOptions);
  }

  account(): Observable<any> {
    // let timestamp;
    // this.time().subscribe(resp =>{
    //   console.log('resp: ', resp);
    //   timestamp =new Date(resp);
    //   console.log('timestamp: ', timestamp);
    // });

    let date = new Date();
    // const localOffset = date.getTimezoneOffset() * 60000;
    const localTime = date.getTime()+2500;
    const timestamp1 = localTime ; //unix timestamp in seconds;
    console.log('timestamp1: ', timestamp1);

    const apiSecret ='yz07heOyh71CcKRQkmChqXDpnnAv0HURY1BBgflDHreR0xa4a6leFADJbC4ajyCY';
    var secret = CryptoJS.enc.Base64.parse(apiSecret);

    const query_string = 'timestamp='+timestamp1;
    //const query_string = 'recvWindow=60000'+'&timestamp='+timestamp1;

    const ciphertext = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, apiSecret);
    // const ciphertext = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
    ciphertext.update(query_string);


    console.log('apiSecret: ', apiSecret);
    console.log('query_string: ', query_string);
    // console.log('ciphertext: ', ciphertext);
    // console.log('hashDigest', CryptoJS.enc.Hex.stringify(ciphertext.finalize())); // correct

    let paramsS = new HttpParams();
    // paramsS = paramsS.set('recvWindow', '60000');
    paramsS = paramsS.set('timestamp', ''+timestamp1);
    // paramsS = paramsS.set('signature', ciphertext);
    paramsS = paramsS.set('signature', CryptoJS.enc.Hex.stringify(ciphertext.finalize()));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept':'application/json',
        'X-MBX-APIKEY': 'RrNZGZ8q5ygHuxU3NDSuuRGIQBaeLAsvoeQuJUc8haQWfzUjLT6u50UvryA3DBs9'
        // 'Access-Control-Allow-Headers':'Content-Type',
        // 'Access-Control-Allow-Origin':'*'
      }),
      params: paramsS
    };
    // return this.http.get<any>('https://api.binance.com/api/v3/account', httpOptions);
    return this.http.get<any>('/api/v3/account', httpOptions);
  }
}


