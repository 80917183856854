import { Component, OnInit } from '@angular/core';
import { CryptosService } from 'src/app/servicios/crypto/cryptos.service';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { DatePipe } from '@angular/common';
import { CryptosMiasModel } from 'src/app/models/cryptosMiasModel';

@Component({
  selector: 'app-mias-form',
  templateUrl: './mias-form.component.html',
  styleUrls: ['./mias-form.component.css']
})
export class MiasFormComponent implements OnInit {

  // pipe = new DatePipe('en-US');
  datePipe = new DatePipe('en-US');

  // Calendario theme-default, theme-green, theme-blue, theme-dark-blue, theme-red, theme-orange
  colorTheme = 'theme-dark-blue';
  locale = 'es';
  locales = listLocales();
  bsConfig: Partial<BsDatepickerConfig>;
  myDateValue: Date;

  // Mask
  // public maskRut = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskRut = [ /[1-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9kK]/];
  // public maskMovil = ['+', /\d/, /\d/, ' ', /[9]/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  public maskPrecio = ['$', /\d/, /\d/, /\d/, '.', /[9]/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];

  constructor(
    private localeService: BsLocaleService,
    public cryptoService: CryptosService,
    private toastrService: ToastrService,
    public flasMensaje: FlashMessagesService,
    public router: Router
  ) { }

  ngOnInit() {
    // Configuracion color calendario
    this.localeService.use(this.locale);
    // this.myDateValue = new Date();
    // this.getFecha();
    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme,
      dateInputFormat: 'DD-MM-YYYY'
      // customTodayClass: 'custom-today-class'
     });

    this.cryptoService.getCryptosMias();
    this.resetForm();

  }

  resetForm(cryptoMiasForm?: NgForm) {
    if (cryptoMiasForm != null) {
      cryptoMiasForm.reset();
      this.cryptoService.selectedCryptosMias =  new CryptosMiasModel();
    }
  }

  cleanForm(cryptoMiasForm?: NgForm) {
    this.resetForm(cryptoMiasForm);
    this.router.navigate(['/misCrypto']);
  }

  onSubmit(cryptoMiasForm: NgForm) {
    // cryptoMiasForm.value.fechaRenovacion = this.datePipe.transform(cryptoMiasForm.value.fechaRenovacion, 'dd-MM-yyyy');
    // this.setFecha(cryptoMiasForm);

    //Nuevo Registro
    if (cryptoMiasForm.value.$key == null) {
      this.cryptoService.insertCryptosMias(cryptoMiasForm.value);
      // this.toastrService.success('Operacion exitosa', 'Cliente Registrado');
      this.toastrService.success('This is success toast.', 'Success!');
      this.flasMensaje.show('Operacion exitosa', {cssClass: 'alert-success', timeout: 4000});
    } else {
      this.cryptoService.updateCryptosMias(cryptoMiasForm.value);
      // this.toastrService.success('Operacion exitosa', 'Cliente Modificado');
      this.toastrService.success('This is success toast.', 'Success!');
      this.flasMensaje.show('Operacion exitosa', {cssClass: 'alert-success', timeout: 4000});
    }
    this.resetForm(cryptoMiasForm);
    this.router.navigate(['/misCrypto']);
  }


}
