import { Component, OnInit } from '@angular/core';
import { CryptosService } from 'src/app/servicios/crypto/cryptos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { CryptoBalanceModel } from 'src/app/models/cryptosBalanceModel';
import { CryptosMiasModel } from 'src/app/models/cryptosMiasModel';
import { CryptoSeguimientoModel } from 'src/app/models/cryptosSegui';
import StringUtils from 'src/app/utils/string-utils';
import { isNullOrUndefined } from 'util';
import { CargarExcelService } from 'src/app/servicios/excel/cargar-excel.service';
import { CargarExcelModel } from 'src/app/models/cargarExcelModel';
import TablaUtils from 'src/app/utils/tablaUtils';
import { CargarExcelBalanceModel } from 'src/app/models/cargarExcelBalanceModel';
import BalanceUtils from 'src/app/utils/balanceUtils';
import { CoinMarketCapService } from 'src/app/servicios/coin-market-cap/coin-market-cap.service';
import { BinanceService } from 'src/app/servicios/binance/binance.service';

@Component({
  selector: 'app-balance2',
  templateUrl: './balance2.component.html',
  styleUrls: ['./balance2.component.css']
})
export class Balance2Component implements OnInit {

  coinMarketCapList: any[] = [];
  coinMarketCapMetadadaList: any[] = [];

  cargarExcelModelList: CargarExcelModel[] = [];
  cryptoBalanceList: CryptoBalanceModel[] = [];
  balancePortafolioList: CryptoBalanceModel[] = [];
  balancePortafolioVendidasList: CryptoBalanceModel[] = [];
  cryptoSeguimientoList : CryptoSeguimientoModel[];

  // ngx-pagination
  itemsPerPage = 50;
  pageActual = 1;

  // ngx-pagination
  itemsPerPage2 = 5;
  pageActual2 = 1;

  // ngx-pagination
  itemsPerPage3 = 25;
  pageActual3 = 1;

  CONST_PRICE: string = 'PRICE';
  CONST_AMOUNT: string = 'AMOUNT';
  CONST_TOTAL: string = 'TOTAL';
  CONST_FEE: string = 'FEE';


  constructor(
    private cryptosService: CryptosService,
    // private operacionesServices: CargarExcelService,
    private cargarExcelService: CargarExcelService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private flasMensaje: FlashMessagesService,
    private coinMarketCapService: CoinMarketCapService,
    private binancaService: BinanceService
  ) { }

  ngOnInit() {
    this.getCryptoBalance();
  }

   /**
   * Determina si debe cargar los pagos de XCliente o de todos
   */
  getCryptoBalance() {
    // Obtener seguimiento
    // this.getCryptoSeguimiento();

    // this.getOpesBD();
    // this.getCryptoSeguimiento();
    this.getListaOperaciones();
  }


  static ordenarCryptoBalanceList(list: CryptoBalanceModel[]){
    console.log('list: ', list);
    // this.cryptoBalanceList.sort((a, b) => TablaUtils.compararCamposFechas2(a.fecha, b.fecha));
    list.sort((a, b) => TablaUtils.compararCamposFechas3(a.fecha, b.fecha));
    let i = 1;
    list.forEach(x => {
      x.id = (i++).toString();
    });
  }

  static ordenarCryptoBalanceList2(list: CryptoBalanceModel[]){
    // console.log('list: ', list);
    // this.cryptoBalanceList.sort((a, b) => TablaUtils.compararCamposFechas2(a.fecha, b.fecha));
    list.sort((a, b) => TablaUtils.compararCamposFechas2(a.fecha, b.fecha));
    let i = 1;
    list.forEach(x => {
      x.id = (i++).toString();
    });
  }

  irBinance(par: string){
    const tickerMia = StringUtils.getTikerMias(par);
    // abrir un PDF en una pestaña nueva
    // window.open('http://ejemplo.com/archivo.pdf', '_blank');

    // redirigir la pestaña actual a otra URL
    // window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');

    window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');
  }

  /********************************************************
   *  BALANCE agrupados MAP
   *********************************************************/
  /**
   * Obtien la lista de las operaciones realizadas en Binance que fueron cargadas en BD
   */
  getListaOperaciones(){
    //Agrupar
    let map2 = new Map<string, CargarExcelBalanceModel>();
    let listaX: CargarExcelModel[] = [];
    let opsAgrupadas: CargarExcelModel[] = [];

   this.cargarExcelService.getCargarExcelModel()
   .snapshotChanges() // Obtine todos los cambios desde la BD
   .subscribe(item => { // Trae los datos insetados
     this.cargarExcelModelList = [];
     let i = 1;
     let iBuy = 1;
     item.forEach(element => {
       // this.totalItems ++;
       // console.log('element.key: ',element.key);
       const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
       x['id'] = i++;
       x['$key'] = element.key;
       // Agregar Class
       x['sellClass'] = StringUtils.addClassDanger(x['type'], 'SELL');

       // Limpiar Caracteres
       // x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
       this.cargarExcelModelList.push(x as CargarExcelModel);
       listaX.push(x as CargarExcelModel);

       if ('BUY' == x['type']) {
         // this.buyList.push(x as CargarExcelModel);
         this.getSeguimiento(x, iBuy++);
       }
       // console.log('id: ', x['id']);
     });
     opsAgrupadas = this.agruparMarketCryptosB3(listaX, map2);
   });
   console.log('map2: ', map2);
   console.log('opsAgrupadas: ', opsAgrupadas);

 }


 /**
  * Agrupa todas las cryptos
  * @param listaX
  * @param map2
  */
 agruparMarketCryptosB3(listaX: any[], map2: any){
   // let map2 = new Map<string, CargarExcelBalanceModel>();
   let opsAgrupadas: CargarExcelModel[] = [];

   listaX.forEach(d => {
    // console.log('d.market: ',d.market);

     //Verifica que el market no este en la lista
     if (isNullOrUndefined(map2.get(d.market))) {
       let obj = new CargarExcelBalanceModel();
       let listBuy: CargarExcelModel[] = [];
       let listSell: CargarExcelModel[] = [];

       // Agrupa el mismo Market y Type de operaciones
       opsAgrupadas = listaX.filter(x => x.market == d.market);

      if (!isNullOrUndefined(opsAgrupadas)) {
         listBuy = opsAgrupadas.filter(y => y.type == 'BUY');
         listSell = opsAgrupadas.filter(z => z.type === 'SELL');

         let sumaTotalBuy = BalanceUtils.sumaPreciosBuySell(listBuy, 'priceProm');
         let sumaTotalSell = BalanceUtils.sumaPreciosBuySell(listSell, 'priceProm');
         // console.log('sumaTotalBuy: ',sumaTotalBuy);
         // console.log('sumaTotalSell: ',sumaTotalSell);

        obj.market = d.market;
        obj.fecha = d.fecha;
        obj.type = d.type;

        obj.listaBuy = listBuy;
        obj.listaSell = listSell;
        obj.numBuy = listBuy.length;
        obj.numSell = listSell.length;

        obj.priceProm = (sumaTotalBuy / listBuy.length).toString();
        obj.amountTotal = (BalanceUtils.sumaPreciosBuySell(listBuy, 'amountTotal') - BalanceUtils.sumaPreciosBuySell(listSell, 'amountTotal')).toString();
        obj.totalTotal = (BalanceUtils.sumaPreciosBuySell(listBuy, 'totalTotal') - BalanceUtils.sumaPreciosBuySell(listSell, 'totalTotal')).toString();

        //Si aun quedan criptos y se ha recuperado la inversion, mayor a 1 para quitar las mermas
        if (1 < Number(obj.amountTotal) && 0 > Number(obj.totalTotal)) {
          // obj.totalTotal = (BalanceUtils.sumaPreciosBuySell(listBuy, 'totalTotal')).toString();
          obj.totalTotal = (BalanceUtils.sumaPreciosBuySell(listSell, 'totalTotal') - BalanceUtils.sumaPreciosBuySell(listBuy, 'totalTotal') ).toString();
          // no es precisa lla que suma todas las ventas y puede darse el caso de que algunas ya fueron finalizadas
          obj.gananciaEfectiva = (BalanceUtils.sumaPreciosBuySell(listBuy, 'totalTotal') + Number(obj.totalTotal)).toString();
          obj.inverRecuperada = 'SI';
        }

         // obj.feeTotal = (BalanceUtils.sumaPreciosBuySell(listBuy, 'feeTotal') + BalanceUtils.sumaPreciosBuySell(listSell, 'feeTotal')).toString();
         obj.feeTotal = BalanceUtils.sumaPreciosBuySell(listBuy, 'feeTotal').toString();
      }

       map2.set(d.market, obj);
     }
   });

   this.getCryptoCoinMarketCap(map2);
  //  this.getSeguimiento2(map2);
   return map2;
 }

 /**
  * Realiza los calculos necesarios de precios cantidas y busca los datos de la crypto en la lista de seguimiento
  * @param map
  */
 getSeguimiento2(map: any){
   let i = 0;
   let cryptoMias: string ='';

   map.forEach((value: CargarExcelBalanceModel, key: string) => {
     // console.log(key, value);
     const tikerMia = StringUtils.getTikerMias2(key, 'USDT');
    //  console.log('tikerMia: ', tikerMia);

    //  const cryptoS: CryptoSeguimientoModel = this.cryptoSeguimientoList.find(cs => cs.ticker === tikerMia);
     const cryptoS = this.coinMarketCapList.find(cs => cs.symbol === tikerMia);
     const metaData = this.coinMarketCapMetadadaList.find(cs => cs.symbol === tikerMia);
    //  console.log('cryptoS: ', cryptoS);
    //  console.log('metaData: ', metaData);

     if (!isNullOrUndefined(cryptoS) && !isNullOrUndefined(metaData)) {
       let model =  new CryptoBalanceModel();
       model.id = '' + i++ ;
       model.fecha = value.fecha;
       model.tipo = value.type;
       model.par = value.market;
       model.precioUsdt = Number(value.priceProm).toFixed(8).toString();
       model.completo = Number(value.amountTotal).toFixed(8).toString();
       model.totalUsdt = Number(value.totalTotal).toFixed(8).toString();
       model.comisionValor = Number(value.feeTotal).toFixed(8).toString();
       model.comisionTiker = value.feeCoinTotal;
       model.numCompras = value.numBuy.toString();
       model.numVentas = value.numSell.toString();

       model.icono = metaData.logo;
       model.precioActualUsdt = cryptoS.quote.USD.price;
       model.valorActual = StringUtils.calcularValorActual3(model.completo, model.precioActualUsdt).toFixed(8).toString();
       model.ganancia = StringUtils.calcularGanancia3(model.completo, cryptoS.quote.USD.price, model.totalUsdt ).toFixed(8).toString();
       model.gananciaPorcentaje = ''+ StringUtils.calcularGananciaPorcentaje2(model.totalUsdt , model.valorActual).toFixed(2);

       model.gananciaClass = StringUtils.validarDatoNegativoAddClass(model.ganancia, null);
       model.gananciaPorcentajeClass = StringUtils.validarGananciaXAddClass(model.gananciaPorcentaje, null, 50);
       model.precioActualClass = 'table-warning';
       model.inverRecuperada = value.inverRecuperada;
       model.gananciaEfectiva = value.gananciaEfectiva;

       if (0 >= Number(value.amountTotal) || 0 >= Number(value.totalTotal) ) {
         this.balancePortafolioVendidasList.push(model);
       } else {
         this.balancePortafolioList.push(model);
       }
      // this.balancePortafolioList.push(model);

     }else {
       console.log('Seguimiento: ', key +' - No esta en Seguimiento ');
     }

   });
   Balance2Component.ordenarCryptoBalanceList2(this.balancePortafolioList);
 }

 getSeguimiento(x: any, i: number){

   const tikerMia = StringUtils.getTikerMias2(x['market'], 'USDT');
   // console.log('tikerMia: ', tikerMia);
  //  const cryptoS: CryptoSeguimientoModel = this.cryptoSeguimientoList.find(cs => cs.ticker === tikerMia);
    const cryptoS = this.coinMarketCapList.find(cs => cs.symbol === tikerMia);
     const metaData = this.coinMarketCapMetadadaList.find(cs => cs.symbol === tikerMia);
   // console.log('cryptoS: ', cryptoS);

   if (!isNullOrUndefined(cryptoS) && !isNullOrUndefined(metaData)) {
     let model =  new CryptoBalanceModel();
     model.id = i.toString() ;
     model.fecha = x['fecha'];
     model.tipo = x['type'];
     model.par = x['market'];
     model.precioUsdt = x['price'];
     model.completo = x['amount'];
     model.totalUsdt = x['total'];
     model.icono = cryptoS.icon;
     model.precioActualUsdt = cryptoS.price;
     model.valorActual = StringUtils.calcularValorActual3(model.completo, model.precioActualUsdt).toFixed(8).toString();
     model.ganancia = StringUtils.calcularGanancia3(model.completo, cryptoS.price, model.totalUsdt ).toFixed(8).toString();
     model.gananciaPorcentaje = ''+ StringUtils.calcularGananciaPorcentaje2(model.totalUsdt , model.valorActual).toFixed(2);

     model.gananciaClass = StringUtils.validarDatoNegativoAddClass(model.ganancia, null);
     model.gananciaPorcentajeClass = StringUtils.validarGananciaXAddClass(model.gananciaPorcentaje, null, 50);

     this.cryptoBalanceList.push(model);
   }else {
     console.log('Seguimiento: ', x.market +' - No esta en Seguimiento ');
   }

   Balance2Component.ordenarCryptoBalanceList2(this.cryptoBalanceList);
 }

 /**
  * Obtinen las criptomodendas en seguimiento que me pertenecen
  */
 getCryptoSeguimiento() {
   // Obtener seguimiento
   this.cryptosService.getCryptosSeguimiento()
   .snapshotChanges() // Obtine todos los cambios desde la BD
   .subscribe(item => { // Trae los datos insetados
     this.cryptoSeguimientoList = [];
     let i = 1;

     item.forEach(element => {
       const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
       x['id'] = i++;
       x['$key'] = element.key;

       // Limpiar Caracteres
       x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
       x['price'] = StringUtils.limpiarDatoRemplazaX(x['price'], '$');

       // Agregar Objeto Limpio
       this.cryptoSeguimientoList.push(x as CryptoSeguimientoModel);
       // console.log('id: ', x['id']);
     });
     console.log('cryptoSeguimientoList: ', this.cryptoSeguimientoList);
   });
 }



 /**
  * Obtiene el tiket de todas las cryptos que s e  operaron y  recupera s info del servicio de coinMarketCap API
  * @param map
  */
 getCryptoCoinMarketCap(map: any){
  let i = 0;
  let cryptoMias: string ='';

  map.forEach((value: CargarExcelBalanceModel, key: string) => {
    const tikerMia = StringUtils.getTikerMias2(key, 'USDT');
   //  console.log('tikerMia: ', tikerMia);
     cryptoMias = cryptoMias + tikerMia+',';
  });

  this.coinMarketCapService.latestSymbols(cryptoMias.substring(0,cryptoMias.length-1)).subscribe(resp=>{
    // console.log('resp', resp);
    Object.entries(resp.data).forEach(element => {
      // console.log('element: ', element[1]);
      this.coinMarketCapList.push(element[1]);
    });

    this.coinMarketCapService.metadatos(cryptoMias.substring(0,cryptoMias.length-1)).subscribe(resp=>{
      // console.log('metadatos - resp', resp);
      Object.entries(resp.data).forEach(element => {
        // console.log('element: ', element[1]);
        this.coinMarketCapMetadadaList.push(element[1]);
      });
      this.getSeguimiento2(map);
    });

  });

  this.binancaService.account().subscribe(resp=>{
    console.log('resp', resp);
  });


}


}
