export class CryptoBalanceModel {
  id: string;
  $key: string;
  fecha: Date;
  tipo: string;
  par: string;
  // lado: string;
  precioUsdt: string;
  completo: string;
  comisionValor: string;
  comisionTiker: string;
  totalUsdt: string;
  // favorita: string;
  precioActualUsdt: string;
  valorActual: string;
  ganancia: string;
  gananciaPorcentaje: string;
  accion: string;
  icono: string;

  // Porpiedades de estilos
  gananciaClass: string;
  gananciaPorcentajeClass: string;
  precioActualClass: string;


  // Balance compra venta
  numCompras: string;
  numVentas: string;
  inverRecuperada: string;
  gananciaEfectiva: string;
}
