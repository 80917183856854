import { Component, OnInit } from '@angular/core';
import { CryptoBalanceModel } from 'src/app/models/cryptosBalanceModel';
import { CryptosService } from 'src/app/servicios/crypto/cryptos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { CryptosMiasModel } from 'src/app/models/cryptosMiasModel';

@Component({
  selector: 'app-mias',
  templateUrl: './mias.component.html',
  styleUrls: ['./mias.component.css']
})
export class MiasComponent implements OnInit {

   // ngx-pagination
   itemsPerPage = 10;
   pageActual = 1;

  cryptosMiasModelList: CryptosMiasModel[] = [];

  constructor(
    private cryptosService: CryptosService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private flasMensaje: FlashMessagesService
  ) { }

  ngOnInit() {

    this.cryptosService.getCryptosMias()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.cryptosMiasModelList = [];
      let i = 1;
      item.forEach(element => {
        // this.totalItems ++;
        // console.log('element.key: ',element.key);
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['id'] = i++;
        x['$key'] = element.key;
        // Agregar Class
        // x['percentage24Class'] = StringUtils.validarDatoNegativoAddClass(x['percentage24']);
        // x['marketCappercentageClass'] = StringUtils.validarDatoNegativoAddClass(x['marketCappercentage']);
        // x['volume24percentageClass'] = StringUtils.validarDatoNegativoAddClass(x['volume24percentage']);

        // Limpiar Caracteres
        // x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');

        this.cryptosMiasModelList.push(x as CryptosMiasModel);
        // console.log('id: ', x['id']);
      });
    });
  }

  onEdit(cryptosMiasModel: CryptosMiasModel) {
    // Mostrar
    this.router.navigate(['/newMisCrypto']);
    this.cryptosService.selectedCryptosMias = Object.assign({}, cryptosMiasModel);
  }

}
