import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CargarExcelModel } from 'src/app/models/cargarExcelModel';
import { CargarExcelService } from 'src/app/servicios/excel/cargar-excel.service';
import StringUtils from 'src/app/utils/string-utils';
import { CryptoSeguimientoModel } from 'src/app/models/cryptosSegui';
import { CryptoBalanceModel } from 'src/app/models/cryptosBalanceModel';
import { CryptosService } from 'src/app/servicios/crypto/cryptos.service';
import { isNullOrUndefined } from 'util';
import { CargarExcelBalanceModel } from 'src/app/models/cargarExcelBalanceModel';
import TablaUtils from 'src/app/utils/tablaUtils';
import { Balance2Component } from '../balance2/balance2.component';
import BalanceUtils from 'src/app/utils/balanceUtils';

// import { TreeTableData, TreeTableHeaderObject, TreeTableRow } from 'angular-tree-table/src/public-api';

@Component({
  selector: 'app-lista-operaciones',
  templateUrl: './lista-operaciones.component.html',
  styleUrls: ['./lista-operaciones.component.css']
})
export class ListaOperacionesComponent implements OnInit {

  cargarExcelModelList: CargarExcelModel[] = [];
  cryptoBalanceList: CryptoBalanceModel[] = [];
  balancePortafolioList: CryptoBalanceModel[] = [];
  balancePortafolioVendidasList: CryptoBalanceModel[] = [];
  cryptoSeguimientoList : CryptoSeguimientoModel[];

  // Filtro Busqueda
  price = '';
  nombre = '';

  // ngx-pagination
  itemsPerPage = 5;
  pageActual = 1;

  // ngx-pagination
  itemsPerPage2 = 100;
  pageActual2 = 1;


  constructor(
    private cargarExcelService: CargarExcelService,
    private cryptosService: CryptosService,
    private cdRef:ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getCryptoSeguimiento();
    this.getListaOperaciones();
  }

  /**
   * Obtien la lista de las operaciones realizadas en Binance que fueron cargadas en BD
   */
  getListaOperaciones(){
     //Agrupar
     let map2 = new Map<string, CargarExcelBalanceModel>();
     let listaX: CargarExcelModel[] = [];
     let opsAgrupadas: CargarExcelModel[] = [];

    this.cargarExcelService.getCargarExcelModel()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.cargarExcelModelList = [];
      let i = 1;
      let iBuy = 1;
      item.forEach(element => {
        // this.totalItems ++;
        // console.log('element.key: ',element.key);
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['id'] = i++;
        x['$key'] = element.key;
        // Agregar Class
        x['sellClass'] = StringUtils.addClassDanger(x['type'], 'SELL');

        // Limpiar Caracteres
        // x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
        this.cargarExcelModelList.push(x as CargarExcelModel);
        listaX.push(x as CargarExcelModel);

        if ('BUY' == x['type']) {
          // this.buyList.push(x as CargarExcelModel);
          this.getSeguimiento(x, iBuy++);
        }
        // console.log('id: ', x['id']);
      });
    });

  }

  getSeguimiento(x: any, i: number){

    const tikerMia = StringUtils.getTikerMias2(x['market'], 'USDT');
    // console.log('tikerMia: ', tikerMia);
    const cryptoS: CryptoSeguimientoModel = this.cryptoSeguimientoList.find(cs => cs.ticker === tikerMia);
    // console.log('cryptoS: ', cryptoS);

    if (!isNullOrUndefined(cryptoS)) {
      let model =  new CryptoBalanceModel();
      model.id = i.toString() ;
      model.fecha = x['fecha'];
      model.tipo = x['type'];
      model.par = x['market'];
      model.precioUsdt = x['price'];
      model.completo = x['amount'];
      model.totalUsdt = x['total'];
      model.icono = cryptoS.icon;
      model.precioActualUsdt = cryptoS.price;
      model.valorActual = StringUtils.calcularValorActual3(model.completo, model.precioActualUsdt).toFixed(8).toString();
      model.ganancia = StringUtils.calcularGanancia3(model.completo, cryptoS.price, model.totalUsdt ).toFixed(8).toString();
      model.gananciaPorcentaje = ''+ StringUtils.calcularGananciaPorcentaje2(model.totalUsdt , model.valorActual).toFixed(2);

      model.gananciaClass = StringUtils.validarDatoNegativoAddClass(model.ganancia, null);
      model.gananciaPorcentajeClass = StringUtils.validarGananciaXAddClass(model.gananciaPorcentaje, null, 50);

      this.cryptoBalanceList.push(model);
    }else {
      console.log('Seguimiento: ', x.market +' - No esta en Seguimiento ');
    }

    Balance2Component.ordenarCryptoBalanceList2(this.cryptoBalanceList);
  }

  /**
   * Obtinen las criptomodendas en seguimiento que me pertenecen
   */
  getCryptoSeguimiento() {
    // Obtener seguimiento
    this.cryptosService.getCryptosSeguimiento()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.cryptoSeguimientoList = [];
      let i = 1;

      item.forEach(element => {
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['id'] = i++;
        x['$key'] = element.key;

        // Limpiar Caracteres
        x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
        x['price'] = StringUtils.limpiarDatoRemplazaX(x['price'], '$');

        // Agregar Objeto Limpio
        this.cryptoSeguimientoList.push(x as CryptoSeguimientoModel);
        // console.log('id: ', x['id']);
      });
      console.log('cryptoSeguimientoList: ', this.cryptoSeguimientoList);
    });
  }

  irBinance(par: string){
    const tickerMia = StringUtils.getTikerMias2(par, 'USDT');
    // abrir un PDF en una pestaña nueva
    // window.open('http://ejemplo.com/archivo.pdf', '_blank');

    // redirigir la pestaña actual a otra URL
    // window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');

    window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');
  }


}
