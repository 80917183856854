import { Component, OnInit, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CryptoSeguimientoModel } from 'src/app/models/cryptosSegui';
import { CryptosService } from 'src/app/servicios/crypto/cryptos.service';
import { ToastrService } from 'ngx-toastr';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import StringUtils from 'src/app/utils/string-utils';
// import { ScrapingService } from 'src/app/servicios/scraping/scraping.service';

@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.css']
})
export class SeguimientoComponent implements OnInit {

  datePipe = new DatePipe('en-US');

  // Filtro Busqueda
  price = '';
  nombre = '';

  // ngx-pagination
  itemsPerPage = 10;
  pageActual = 1;

  cryptoSeguimientoList : CryptoSeguimientoModel[];

  miasSeguimientoList : CryptoSeguimientoModel[];
  pCompraSeguimientoList : CryptoSeguimientoModel[];

  constructor(
    public cryptosService: CryptosService,
    public toastrService: ToastrService,
    // public scraping: ScrapingService,
    private elementRef: ElementRef,
    public flasMensaje: FlashMessagesService,
    public router: Router
  ) { }

  ngOnInit() {
    this.getAllSeguimiento();
    this.getAllSeguimientoPC();
  }

  getAllSeguimiento(){
    this.cryptosService.getCryptosSeguimiento()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.cryptoSeguimientoList = [];
      let i = 1;
      item.forEach(element => {
        // this.totalItems ++;
        // console.log('element.key: ',element.key);
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['id'] = i++;
        x['$key'] = element.key;
        // Agregar Class
        x['percentage24Class'] = StringUtils.validarDatoNegativoAddClass(x['percentage24'], '%');
        x['marketCappercentageClass'] = StringUtils.validarDatoNegativoAddClass(x['marketCappercentage'],'%');
        x['volume24percentageClass'] = StringUtils.validarDatoNegativoAddClass(x['volume24percentage'],'%');

        // Limpiar Caracteres
        x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
        this.cryptoSeguimientoList.push(x as CryptoSeguimientoModel);

        // console.log('id: ', x['categoria']);
      });
      console.log('this.cryptoSeguimientoList: ', this.cryptoSeguimientoList);

    });
  }

  getAllSeguimientoPC(){
    this.cryptosService.getCryptosSeguimientoPC()
    .snapshotChanges() // Obtine todos los cambios desde la BD
    .subscribe(item => { // Trae los datos insetados
      this.pCompraSeguimientoList = [];
      let i = 1;
      item.forEach(element => {
        // this.totalItems ++;
        // console.log('element.key: ',element.key);
        const x = element.payload.toJSON(); // El contenido del elemento lo convierte  Json
        x['id'] = i++;
        x['$key'] = element.key;
        // Agregar Class
        x['percentage24Class'] = StringUtils.validarDatoNegativoAddClass(x['percentage24'], '%');
        x['marketCappercentageClass'] = StringUtils.validarDatoNegativoAddClass(x['marketCappercentage'],'%');
        x['volume24percentageClass'] = StringUtils.validarDatoNegativoAddClass(x['volume24percentage'],'%');

        // Limpiar Caracteres
        x['rank'] = StringUtils.limpiarDato(x['rank'], 'Puesto # ');
        this.pCompraSeguimientoList.push(x as CryptoSeguimientoModel);

        // console.log('id: ', x['categoria']);
      });
      console.log('this.pCompraSeguimientoList: ', this.pCompraSeguimientoList);

    });
  }

  getCryptosSeguimiento(){
    // this.scraping.getCryptosSeguimiento();
  }

  getCryptosSeguimientoPC(){
    // this.scraping.getCryptosSeguimientoPC();
  }

  irBinance(par: string){
    console.log('irBinance - par', par);
    const tickerMia = StringUtils.getTikerMias2(par, 'USDT');
    // abrir un PDF en una pestaña nueva
    // window.open('http://ejemplo.com/archivo.pdf', '_blank');

    // redirigir la pestaña actual a otra URL
    // window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');

    window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');
  }

  irCionMarketCap(par: string){
    const tickerMia = StringUtils.getTikerMias2(par, 'USDT');
    // abrir un PDF en una pestaña nueva
    // window.open('http://ejemplo.com/archivo.pdf', '_blank');

    // redirigir la pestaña actual a otra URL
    // window.open('https://www.binance.com/es/trade/'+ tickerMia +'_USDT?layout=pro', '_blank');

    window.open('https://coinmarketcap.com/es/currencies/bitcoin/', '_blank');
  }


}
